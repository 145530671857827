<template>
    <div v-if="dialog" class="relative z-50">
        <div class="fixed inset-0 bg-black bg-opacity-80"></div>
        <div class="fixed inset-0 overflow-auto">
            <div class="flex items-center justify-center h-full">
                <div class="bg-white rounded-lg p-4 w-full max-w-xs">
                    <div>
                        <div class="text-lg font-bold">Verify OTP</div>
                        <v-otp-input
                            v-show="pin.visible"
                            ref="pinInput"
                            class="flex justify-between mt-4"
                            :num-inputs="6"
                            separator=""
                            :input-type="pin.type"
                            autocomplete="off"
                            input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
                            @on-change="setPin"
                            @on-complete="setPin"
                        />
                    </div>
                    <div class="flex mt-8 justify-between">
                        <button
                            @click="cancel"
                            class="bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg"
                        >
                            Cancel
                        </button>
                        <button
                            @click="confirm"
                            :disabled="isInvalidPin"
                            class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg ml-4"
                        >
                            {{ button }}
                        </button>
                    </div>
                    <div class="flex mt-4">
                        <div class="text-center text-sm mt-8">
                            <span class="font-medium">Didn't receive an OTP ?</span>
                            <span @click="resendOtp" class="cursor-pointer font-bold">
                            Resend OTP</span
                            >
                            <span v-show="sendOtpInCooldown">
                            in
                            <span class="text-red-500 font-semibold"
                                >{{ otpTimerMinutes }} : {{ otpTimerSeconds }}</span
                            >
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                pin: { value: '', visible: true, type: 'number' },
                otpTimer: 0,
                scope: "",
                button: ''
            };
        },

        computed: {
            isInvalidPin() {
                return this.pin.value.toString().length != 6;
            },
            isInvalidOtp() {
                return this.otp.toString().length != 6;
            },
            otpTimerMinutes() {
                const val = Math.floor(this.otpTimer / 60);
                return val < 10 ? `0${val}` : val;
            },
            otpTimerSeconds() {
                const val = this.otpTimer - this.otpTimerMinutes * 60;
                return val < 10 ? `0${val}` : val;
            },
            sendOtpInCooldown() {
                return this.otpTimer > 0;
            },
        },

        methods: {
            setOtpTimer(second = 300) {
                this.otpTimer = second;
                this.startOtpTimerCountdown();
            },
            startOtpTimerCountdown() {
                if (this.otpTimer > 0) {
                    setTimeout(() => {
                        this.otpTimer -= 1;
                        this.startOtpTimerCountdown();
                    }, 1000);
                }
            },
            open({ button, scope, otpTimer }) {
                this.scope = scope;
                this.button = button ?? 'OK';
                this.pin = { value: '', visible: true, type: 'number' };
                this.dialog = true;
                this.setOtpTimer(otpTimer);

                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve;
                    this.rejectPromise = reject;
                });
            },
            async confirm() {
                this.pin.visible = true;
                this.pin.type = 'number';
                this.resolvePromise(this.pin.value);
                this.dialog = false;
            },
            async cancel() {
                this.resolvePromise(null);
                this.dialog = false;
            },
            setPin(val) {
                this.pin.type = 'number';
                this.pin.value = val;
            },
            async resendOtp() {
                if (this.sendOtpInCooldown) {
                    this.$toasted.global.error(
                    "Please wait a moment before sending new OTP"
                    );
                    return;
                }

                this.$store.commit("setOverlayLoading", true);

                try {
                    const response = await this.$http.post(
                    `${this.$apiTripweWebV2}/notifications/send-otp`,
                    {
                        phone: this.$store.state.user.telepon,
                        scope: this.scope,
                    }
                    );

                    if (response.data.status != 200 && response.data.status != 403) {
                        throw response.data.message;
                    }

                    if(response.data.status == 403){
                        this.$toasted.global.error(response.data.message);
                    }else{
                        this.$toasted.global.success(response.data.message);
                    }
                    var curTime = this.$moment();
                    var nextReq = this.$moment(response.data.data.next_request_at);
                    var diff = nextReq.diff(curTime, 'seconds');
                    this.setOtpTimer(diff);
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit("setOverlayLoading", false);
            },
        }
    };
</script>
